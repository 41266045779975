/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');
import Vue from "vue";

window.Vue = require('vue');

loginUnitec();
loginUnitec2()

function loginUnitec() {
    new Vue({
        el: "#login-unitec",
        props: {
            userType: String,
        },
        data() {
            return {
                loginExterno: true,
                loginInterno: false,
                tipocandidato: false,

            }
        },
        computed: {},
        methods: {
            showLogins() {
                if (this.tipocandidato == 0) {
                    this.loginExterno = true;
                    this.loginInterno = false;
                }


                if (this.tipocandidato == 'Externo') {
                    this.loginExterno = true;
                    this.loginInterno = false;

                } else {
                    this.loginExterno = false;
                    this.loginInterno = true;
                }
            }
        }
    });
}

function loginUnitec2() {
    new Vue({
        el: "#login-unitec-2",
        props: {
            userType: String,
        },
        data() {
            return {
                loginExterno: true,
                loginInterno: false,
                tipocandidato: false,

            }
        },
        computed: {},
        methods: {
            showLogins() {
            //    console.log(this.tipocandidato == 0)
                this.loginExterno = true;

                if (this.tipocandidato == 'Externo') {
                    this.loginExterno = true;
                    this.loginInterno = false;

                } else {
                    this.loginExterno = false;
                    this.loginInterno = true;
                }
            }
        }
    });
}
